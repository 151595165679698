import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

import DataProtectionBanner from "@images/hero-bg.jpg"

const DataProtection = () => (
	<Layout>
		<SEO
			title="Data Backup & Data Protection | Boulder County | NeighborhoodIT"
			description="NeighborhoodIT offers enterprise-class data backup and data protection to Boulder County to small businesses in order to help you level the competitive playing field."
		/>
		<div
			id="hero-banner"
			style={{
				backgroundImage: `url(${DataProtectionBanner})`,
				backgroundPosition: `left`,
			}}
		>
			<div className="hero-text">
				<div className="container">
					<div className="row justify-content-between align-items-center text-white">
						<div className="col-12">
							<h1>Data keeps your business alive.</h1>
							<p>
								Are you protecting it with reliable data backup services? How
								important is your business' data? Put another way: How long coud
								you stay afloat without it?
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between align-items-center">
					<div className="col-12">
						<h1>Data Backup and Data Protection Services</h1>
						<p>
							Despite this heavy reliance on technology and electronic
							information, many SMBs use unreliable tape systems and other
							outdated data backup services to protect their data. Others simply
							cross their fingers. Both camps tempt a harsh fate: among those
							that lose their data for 10 days or more, 50 percent will file for
							bankruptcy in the same period, and nine out of ten businesses will
							file within the year.
						</p>
						<p>
							If you're like most small and midsize businesses, you rely heavily
							on your data and IT infrastructure. You use computers, servers,
							and other electronic hardware and software for a wide range of
							core functions, including accounting, shipping, customer
							management, marketing and inventory.
						</p>
						<p className="font-weight-bold">
							The surprising fact is that installation of data backup services
							has never been easier or more cost-effective in order to protect
							and manage data, and ensure business continuity.
						</p>
						<h3 className="font-weight-bold">
							Data backup services: What's included
						</h3>
						<p>
							Needs vary, so we offer a wide menu of data protection packages,
							including subscription services, licensed software and
							combinations of the two. Whether you need backup for one computer
							or hundreds, our packages have the scalability you need.
						</p>
						<ul>
							<li>
								<strong>Data Backup Services:</strong> Secure essential business
								data in your desktops, laptops and servers.
							</li>
							<li>
								<strong>Data Protection Services and retrieval:</strong> Shield
								information from hackers and theft with 256-bit encryption;
								protect your IT infrastructure with Windows-based server
								technology; retrieve your data through a secure web portal.
							</li>
							<li>
								<strong>Recovery:</strong> Restore servers and applications
								quickly with embedded virtual server technology; recover from
								system failure or data loss in minutes (not hours or days).
							</li>
						</ul>
						<p>
							We provide simple, fast and reliable data backup and protection
							services, superior customer service, and unsurpassed value. Our
							comprehensive data backup services are specifically designed to
							help small and midsize businesses effortlessly protect the
							information, systems and applications that keep them going every
							day. Businesses aren't built overnight-but a catastrophic data
							loss can un-build a business in a matter of minutes.
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default DataProtection
